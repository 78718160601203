import { Component, Input, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { map, Observable, of, tap } from 'rxjs';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';
@Component({
  selector: 'app-nav-bar-item',
  templateUrl: './nav-bar-item.component.html',
  styles: [
  ]
})
export class NavBarItemComponent implements OnInit {
  @Input() data!: Route
  @Input() badge?: string = ''
  @Input() basePath?: string = ''
  open: boolean = false
  showIcon: boolean = false
  name$ = new Observable()
  keepHidden : boolean = true
  realChildren$: Observable<Route[]> = new Observable()
  constructor(private subscriptionService: SubscriptionService) {
  }
  ngOnInit(): void {
    this.realChildren$ = this.subscriptionService.availableServices$.pipe(
      map((data) => {
        let finalChildren =  (this.data.children || []).filter(c => c.data?.['name'] && (c.data?.['serviceSlug'] ? data.includes(c.data?.['serviceSlug']) : true))
        this.keepHidden = ((this.data.children?.length || 0) != 0 && finalChildren.length == 0)
        return finalChildren
      }
      ))
    let theServiceSlug = this.data.data?.['serviceSlug'] || ''
    this.name$ = theServiceSlug ? this.subscriptionService.getByServiceSlug(theServiceSlug).pipe(map(s => s?.service?.name || this.data.data?.['name'])) : of(this.data.data?.['name'])
  }
}
